import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Paper, IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const RelatedProductSlider = ({ relatedProducts }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsToShow, setItemsToShow] = useState(3);

    useEffect(() => {
        const updateItemsToShow = () => {
            if (window.innerWidth < 640) {
                setItemsToShow(1); // 1 item on mobile
            } else if (window.innerWidth < 1024) {
                setItemsToShow(1); // 2 items on tablets
            } else {
                setItemsToShow(3); // 3 items on desktop
            }
        };

        updateItemsToShow();
        window.addEventListener('resize', updateItemsToShow);
        return () => window.removeEventListener('resize', updateItemsToShow);
    }, []);

    useEffect(() => {
        if (!relatedProducts.length) return;

        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + itemsToShow) % relatedProducts.length);
        }, 4000);

        return () => clearInterval(interval);
    }, [relatedProducts.length, itemsToShow]);

    const goToSlide = (index) => setCurrentIndex(index);
    const handlePrev = () => setCurrentIndex((prevIndex) => (prevIndex - itemsToShow + relatedProducts.length) % relatedProducts.length);
    const handleNext = () => setCurrentIndex((prevIndex) => (prevIndex + itemsToShow) % relatedProducts.length);

    const currentProducts = (() => {
        if (!relatedProducts.length) return [];
        const totalToShow = Math.min(itemsToShow, relatedProducts.length);
        const endSlice = relatedProducts.slice(currentIndex, currentIndex + totalToShow);
        const wrapSlice = relatedProducts.slice(0, Math.max(0, currentIndex + totalToShow - relatedProducts.length));
        return [...endSlice, ...wrapSlice].slice(0, totalToShow);
    })();

    return (
        <div className="related-products-slider flex flex-col items-center mx-auto p-4 m-16">
            <h3 className="text-2xl font-bold text-gray-700 mb-6">You May Also Like</h3>
            {relatedProducts.length > 0 && (
                <Box display="flex" alignItems="center" position="relative" width="100%">
                    {/* Left Arrow */}
                    <IconButton onClick={handlePrev} className="absolute left-0 z-10" aria-label="Previous">
                        <ArrowBackIos />
                    </IconButton>

                    {/* Products */}
                    <Box
                        display="flex"
                        gap="1rem"
                        className="transition-transform duration-500 ease-in-out w-full"
                        overflow="hidden"
                        justifyContent="center"
                    >
                        {currentProducts.map((product) => (
                            <Link
                                key={product.id}
                                to={`/product-details/${product.id}/${product.catId}`}
                                className="bg-white shadow-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-2xl w-64"
                            >
                                <Paper elevation={4} className="overflow-hidden">
                                    <img
                                        src={product.image}
                                        alt={product.name}
                                        className="w-full h-60 sm:h-40 object-cover transition-transform duration-500 hover:scale-110"
                                    />
                                    <div className="p-3 bg-gray-900 text-white">
                                        <h3 className="text-lg font-semibold truncate">{product.name}</h3>
                                    </div>
                                </Paper>
                            </Link>
                        ))}
                    </Box>

                    {/* Right Arrow */}
                    <IconButton onClick={handleNext} className="absolute right-0 z-10" aria-label="Next">
                        <ArrowForwardIos />
                    </IconButton>
                </Box>
            )}

            {/* Dot Indicators */}
            <div className="flex justify-center mt-4 space-x-2">
                {Array.from({ length: Math.ceil(relatedProducts.length / itemsToShow) }).map((_, index) => (
                    <span
                        key={index}
                        className={`h-3 w-3 cursor-pointer rounded-full transition-all ${index === Math.floor(currentIndex / itemsToShow)
                            ? 'bg-gray-800'
                            : 'bg-gray-400'
                            }`}
                        onClick={() => goToSlide(index * itemsToShow)}
                    />
                ))}
            </div>
        </div>
    );
};

export default RelatedProductSlider;
