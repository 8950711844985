import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const FeatureCards = () => {
  const features = [
    {
      title: "Durability",
      description: "Built to last with top-notch materials.",
      icon: "🔨",
      color: "#3498db", // Blue
    },
    {
      title: "Premium Quality",
      description: "We ensure the best finish and craftsmanship.",
      icon: "✨",
      color: "#f1c40f", // Yellow
    },
    {
      title: "Affordable Prices",
      description: "Luxury within your budget.",
      icon: "💰",
      color: "#2ecc71", // Green
    },
  ];

  const cardContainerRef = useRef(null);

  useEffect(() => {
    const cards = cardContainerRef.current.querySelectorAll(".feature-card");

    // GSAP Scroll-triggered Animation
    gsap.fromTo(
      cards,
      { opacity: 0, y: 40 },
      {
        opacity: 1,
        y: 0,
        duration: 0.1,
        ease: "power4.out",
        stagger: 0.1,
        scrollTrigger: {
          trigger: cardContainerRef.current,
          start: "top 50%",
          end: "bottom 80%",
          toggleActions: "play none none reverse",
        },
      }
    );

    return () => ScrollTrigger.refresh();
  }, []);

  return (
    <div
      ref={cardContainerRef}
      className="grid grid-cols-1 mt-12 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6 lg:px-24 lg:py-16 mb-12"
    >
      {features.map((feature, index) => (
        <div
          key={index}
          className={`feature-card relative bg-white p-6 rounded-lg shadow-md hover:shadow-xl hover:scale-105 transition-all duration-500 transform`}
          style={{
            background: `linear-gradient(145deg, ${feature.color}15, #fff)`,
            border: `1px solid ${feature.color}`,
          }}
        >
          {/* Icon */}
          <div
            className="text-5xl flex text-center items-center mx-auto mb-4 p-3 rounded-full"
            style={{
              color: feature.color,
              background: `${feature.color}20`,
              boxShadow: `0 4px 8px ${feature.color}50`,
            }}
          >
            {feature.icon}

            {/* Title */}
            <h3
              className="text-lg ml-7 font-semibold text-center"
              style={{ color: feature.color }}
            >
              {feature.title}
            </h3>
          </div>

          {/* Description */}
          <p className="text-gray-700 text-center text-sm">{feature.description}</p>

          {/* Glow Effect on Hover */}
          <div
            className="absolute inset-0 bg-gradient-to-br from-transparent via-white to-transparent opacity-0 hover:opacity-20 rounded-2xl transition-opacity duration-500"
          ></div>
        </div>
      ))}
    </div>
  );
};

export default FeatureCards;
