import { Box, Typography, Grid, Container } from '@mui/material';
import img2 from '../../assets/images/contact/2.jpg';
import img3 from '../../assets/images/contact/3.jpg';
import img4 from '../../assets/images/contact/4.png';
import { Link } from 'react-router-dom';
import bgVdo from "../../assets/2.mp4";
const AboutUs = () => {
    return (
        <Box className="w-full text-gray-800 bg-white">
            {/* Hero Section */}
            <Box
                className="relative w-full flex items-center justify-center text-center bg-cover bg-center"
                style={{
                    backgroundImage: `url(${img2})`,
                    height: '70vh',
                }}
            >
                {/* Overlay */}
                <Box className="absolute inset-0 bg-black bg-opacity-40" />

                {/* Hero Content */}
                <Box className="z-10 px-4 mt-24 md:mt-56">
                    <Typography
                        variant="h3"
                        className="text-white text-4xl md:text-6xl font-extrabold leading-tight"
                    >
                        About Us
                    </Typography>
                    <Typography
                        variant="body1"
                        className="text-white text-lg md:text-xl mt-4 max-w-xl mx-auto opacity-80"
                    >
                        Discover the story behind MP Group — craftsmanship and sophistication for modern living.
                    </Typography>
                </Box>
            </Box>

            <Container className="py-16 bg-black shadow-md rounded-b-lg">
                <Typography
                    variant="body1"
                    className="text-white leading-relaxed mb-8 text-justify text-lg px-4 md:px-12"
                >
                    In the world of design and lifestyle, MP Group is a force to reckon with. The company symbolizes impeccable quality and unparalleled service. As one of Nepal's leading importers and retailers of natural stones and tiles, the company has solidified its position in the market. The brand has carved a niche for itself as a pioneer of Imported Marble for the discerning customer ever since they started their retail journey through their first store in Tinkune, Kathmandu, Nepal.
                </Typography>
                <Typography
                    variant="body1"
                    className="text-white leading-relaxed text-justify pt-8 text-lg px-4 md:px-12"
                >
                    Founded in 1989, MP Group has since then grown stronger with its hard work, innovations, and encouragement from our discerning customers. We believe in building your dream, not just selling our products. The company has set a benchmark in the natural stones industry by providing innovative and functional solutions for both residential and commercial spaces in Nepal. Leaders to consultants, architects to interior designers, & builders to contractors believe that our knowledge, thorough quality control, and vast selection set our products right where it should be.
                </Typography>
            </Container>

            {/* Section: Our Inspiration */}
            <Container className="py-16  rounded-3xl">
                <Grid container spacing={6} alignItems="center">
                    {/* Image Section */}
                    <Grid item xs={12} md={6}>
                        <Box
                            component="img"
                            src={img3}
                            alt="Our Inspiration"
                            className="rounded-lg shadow-xl w-full object-cover"
                            style={{
                                maxHeight: '400px',
                                border: '2px solid #e0e0e0',
                            }}
                        />
                    </Grid>

                    {/* Text Content Section */}
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="h4"
                            className="text-gray-800 font-extrabold mb-4 text-center md:text-left"
                        >
                            Our Inspiration
                        </Typography>
                        <Typography
                            variant="body1"
                            className="text-gray-600 leading-relaxed text-lg text-justify px-4 md:px-0"
                        >
                            The consumers' rapidly growing appetite for style and aesthetics is the inspiration behind every design at MP Group. Its ability to keep pace with customer and market demands has made MP Group a synonym for quality, service, and innovation in the national market.
                            <br /><br />
                            The exquisite range of over 100 types of Imported Marbles, Granite, Travertine, Onyx, Sandstone, and Kota stone are procured from the world's finest quarries. Apart from natural stones, you can also explore a variety of Wall & Floor Tiles, Laminate Floors, Quartz stone, Bathware, Sanitary ware, Kitchen Sinks, and Kitchen Appliances. With MP Group, you get everything you need in one place, eliminating the hassle of dealing with multiple vendors.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            {/* Section: Vision & Style */}
            <Container className="py-16 bg-gray-100  shadow-md">
                <Grid container spacing={6} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                        <Typography
                            variant="h4"
                            className="text-gray-800 font-extrabold mb-4 text-center md:text-left"
                        >
                            Vision & Style
                        </Typography>
                        <Typography
                            variant="body1"
                            className="text-gray-600 leading-relaxed text-lg text-justify px-4 md:px-0"
                        >
                            Building a well-loved brand with a set of values focused on the customer has always been the vision for the company. At the heart of all we do lies the aspirations of the customer. We first understand what they seek, then craft an immersive experience for them.
                            <br /><br />
                            Our proposals are always of the highest quality, paired with extensive knowledge and counsel, rounded off from end to end with finesse. We go the extra mile to earn trust and faith. For some, it is a sale, and for others, it's the beginning of a valued relationship.
                            <br /><br />
                            Our motto is *never say never*, and we continue to strive for perfecting your homes.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} className="text-center justify-between ">
                        <Box
                            component="img"
                            src={img4}
                            alt="Vision & Style"
                            className="rounded-lg object-cover items-center justify-center"

                        />
                    </Grid>
                </Grid>
            </Container>

            {/* Call-to-Action */}
            <Box
                className="py-16 bg-black text-center text-white relative overflow-hidden"
            >
                {/* Decorative Circles */}
                <Box className="absolute top-0 left-0 w-40 h-40 bg-white bg-opacity-20 rounded-full blur-2xl transform -translate-x-1/2 -translate-y-1/2" />
                <Box className="absolute bottom-0 right-0 w-40 h-40 bg-white bg-opacity-10 rounded-full blur-3xl transform translate-x-1/3 translate-y-1/3" />

                {/* Content */}
                <Typography
                    variant="h3"
                    className="text-3xl md:text-4xl font-extrabold leading-snug mb-6"
                >
                    Experience the Elegance of MP Group
                </Typography>
                <Typography
                    variant="body1"
                    className="p-8"
                >
                    Step into a world of unparalleled craftsmanship and innovative design. MP Group brings you an exquisite collection of tiles and marbles, designed to elevate your spaces with sophistication and style.
                </Typography>

                {/* Call-to-Action Button */}
                <Box className="mt-8">
                    <Link
                        to="/contact"
                        className="px-6 py-3 text-lg font-semibold rounded-full bg-teal-600 text-white shadow-lg hover:bg-teal-700 transition-all"
                    >
                        Get In Touch
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default AboutUs;
