import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const importAllImages = (r) => {
    let images = [];
    r.keys().map((item) => images.push(r(item)));
    return images;
};

const clients = importAllImages(require.context('../../assets/our_clients', false, /\.(png|jpe?g|svg|jfif|webp)$/));

const OurClients = () => {
    const settings = {
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        arrows: true,
        rows: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    rows: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    rows: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    rows: 1,
                },
            },
        ],
        customPaging: (i) => {
            return (
                <div
                    style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        background: "#FFB400",
                    }}
                />
            );
        },
    };

    return (
        <div className="py-16 bg-gradient-to-r from-black via-gray-800 to-black text-center px-6 relative">
            {/* Section Header */}
            <h2 className="text-4xl font-bold text-white mb-6 uppercase tracking-wide relative inline-block">
                Our Clients
                <span className="absolute -bottom-1 left-0 w-full h-1 bg-gradient-to-r from-orange-400 to-red-600"></span>
            </h2>
            <p className="text-gray-300 mb-10 max-w-3xl mx-auto text-lg leading-relaxed">
                Proudly partnering with leading brands to deliver unmatched quality and
                excellence. Here are some of our trusted clients.
            </p>

            {/* Carousel */}
            <div className="relative mx-auto max-w-6xl">
                <Slider {...settings}>
                    {/* Display clients */}
                    {[...Array(Math.ceil(clients.length / 8))].map((_, index) => (
                        <div key={index} className="px-4">
                            <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-8 gap-6">
                                {clients.slice(index * 8, index * 8 + 8).map((client, i) => (
                                    <div
                                        key={i}
                                        className="group bg-white p-3 rounded-lg shadow-md hover:shadow-lg transition-all duration-500 transform hover:scale-105 relative overflow-hidden"
                                    >
                                        <div className="relative w-full h-0 pb-[100%]">
                                            <img
                                                src={client}
                                                alt={`Client ${index * 8 + i + 1}`}
                                                className="absolute inset-0 w-full h-full object-contain transition-transform duration-300 group-hover:scale-110"
                                                style={{
                                                    filter: "brightness(1.3) contrast(1.2)",
                                                }}
                                            />
                                        </div>
                                        {/* Hover effect */}
                                        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-0 group-hover:opacity-50 transition-all duration-300"></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            {/* Background Decoration */}
            <div className="absolute inset-0 -z-10">
                <div className="h-full w-full bg-gradient-to-r from-gray-700 to-gray-900 opacity-80"></div>
                <div className="h-full w-full absolute top-0 left-0 bg-opacity-10 bg-[radial-gradient(circle_at_center,_#ff5500,_transparent)] animate-pulse"></div>
            </div>
        </div>
    );
};

export default OurClients;
