import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import imgg from '../../assets/images/myImage.jpg';
import { fetchAllProduct } from '../../Redux/Slice/GetProductSlice';
import { fetchproductcategory } from '../../Redux/Slice/GetCategorySlice';

gsap.registerPlugin(ScrollTrigger);

const Variety = () => {
    const { products } = useSelector((state) => state.category);
    const [visibleCount, setVisibleCount] = useState(10);
    const productRefs = useRef([]);
    const dispatch = useDispatch()
    const { AllProduct } = useSelector((state) => state.products);


    console.log(JSON.stringify(products))
    const filteredProducts = products.filter(product => product.products_count > 0);
    useEffect(() => {
        dispatch(fetchAllProduct());
    }, [])

    useEffect(() => {
        dispatch(fetchproductcategory());
    }, [dispatch]);


    useEffect(() => {
        productRefs.current.forEach((product, index) => {
            // GSAP animation for the product cards when they enter the viewport
            gsap.fromTo(
                product,
                { opacity: 0, y: 30, scale: 0.8 },
                {
                    opacity: 1,
                    y: 0,
                    scale: 1,
                    duration: 1,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: product,
                        start: "top 90%",
                        toggleActions: "play none none none",
                    },
                }
            );
        });
    }, [visibleCount]);

    const handleShowMore = () => {
        setVisibleCount((prevCount) => prevCount + 5);
    };

    // Helper function to get the image URL
    const getImageUrl = (product) => {
        if (product.subcategories.length == 0) {
            const foundProduct = AllProduct.find(item => item.id === product.id);
            if (foundProduct && foundProduct.products.length > 0) {
                return foundProduct.products[0].ImageUrl;
            }
        } else {
            return product.subcategories[0]?.subcateimages;
        }
        return null;
    };

    const sortedProducts = filteredProducts.sort((a, b) => {
        const priorityKeywords = [
            "Marble", "Granite", "Tiles", "Quartz", "Tile Adhesive",
            "Faucets", "Sanitaryware", "Bathroom Accessories",
            "Shower", "Body Jet", "Concealed Body", "Thermostat"
        ];

        // Helper function to get the priority index of a product name
        const getPriorityIndex = (name) => {
            // Convert the name to lowercase for case-insensitive comparison
            const nameLower = name.toLowerCase();

            for (let i = 0; i < priorityKeywords.length; i++) {
                if (nameLower.startsWith(priorityKeywords[i].toLowerCase())) {
                    return i;
                }
            }

            return priorityKeywords.length;
        };

        const aPriority = getPriorityIndex(a.name);
        const bPriority = getPriorityIndex(b.name);

        // Compare based on priority
        return aPriority - bPriority;
    });




    return (

        <div style={{ backgroundColor: "#f9f9f9" }} className=" lg:pl-[13rem] lg:pr-[13rem] p-8  min-h-screen text-center">
            <h2 className="lg:text-4xl text-2xl font-extrabold mb-20 lg:mb-20  text-gray-900 ">
                Discover Our Exclusive Collections
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 sm:gap-8 px-4 sm:px-6">
                {sortedProducts.slice(0, visibleCount)?.map((product, index) => (
                    <Link
                        to={product.subcategories.length > 0
                            ? `/sub-category-list/${product.id}/${encodeURIComponent(product.name)}`
                            : `/product-list/category/${product.id}/${encodeURIComponent(product.name)}/${product.id}`}
                        key={index}
                        className="bg-white border border-gray-300 shadow-lg overflow-hidden transition-all transform hover:scale-105 hover:shadow-2xl"
                        ref={el => productRefs.current[index] = el}
                    >
                        <div className="overflow-hidden">
                            <img
                                src={getImageUrl(product)}
                                alt={product.name}
                                className="w-full h-40 sm:h-48 object-cover transition-transform duration-300 ease-in-out hover:scale-110"
                            />
                        </div>
                        <div className="p-4 bg-gray-900">
                            {/* Adjusting text size to be smaller */}
                            <h3 className="sm:text-base text-white text-lg font-semibold truncate">{product.name}</h3>
                        </div>
                    </Link>
                ))}
            </div>

            {sortedProducts && sortedProducts.length > visibleCount && (
                <div className="mt-8">
                    <Tooltip title="Show More" aria-label="show more">
                        <IconButton
                            onClick={handleShowMore}
                            sx={{
                                backgroundColor: '#000',
                                border: '2px solid #fff',
                                borderRadius: '50%',
                                padding: 2,
                                boxShadow: '0px 4px 20px rgba(41, 128, 185, 0.5)',

                                color: '#fff',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.1)',
                                    color: '#000',
                                    boxShadow: '0px 4px 20px rgba(41, 128, 185, 0.5)',
                                },
                            }}
                        >
                            <ExpandMoreIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};

export default Variety;
