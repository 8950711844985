import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import img1 from "../../assets/slider/NATURAL STONES.webp";
import img2 from "../../assets/slider/TILES.jpg";
import img3 from "../../assets/slider/ARTIFICIAL QUARTZ.png";
import img4 from "../../assets/slider/LAMINATE FLOOR.jpeg";
import img5 from "../../assets/slider/BATHWARE AND SANITARYWARE.jpg";
import img6 from "../../assets/slider/KITCHEN SINK.webp";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

gsap.registerPlugin(ScrollTrigger);

const slides = [
    {
        image: img1,
        title: "NATURAL STONES",
        description: "Experience the beauty of nature with our premium natural stone collection.",
    },
    {
        image: img2,
        title: "TILES",
        description: "Explore our vast range of tiles, perfect for transforming your space with style and elegance.",
    },
    {
        image: img3,
        title: "ARTIFICIAL QUARTZ SLABS",
        description: "Discover the durability and sophistication of our artificial quartz slabs, perfect for modern kitchens and bathrooms.",
    },
    {
        image: img4,
        title: "LAMINATE FLOOR",
        description: "Step into the world of laminate flooring, offering a perfect blend of style, durability, and affordability.",
    },
    {
        image: img5,
        title: "BATHWARE & SANITARYWARE",
        description: "Elevate your bathroom experience with our premium bathware and sanitaryware collection, designed for comfort and style.",
    },
    {
        image: img6,
        title: "KITCHEN SINKS",
        description: "Find the perfect kitchen sink to complement your cooking style, from modern designs to functional classics.",
    },
];
const CustomSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef(null);
    const textRef = useRef([]);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
    };

    useEffect(() => {
        // Slider Pop-up Animation
        gsap.fromTo(
            sliderRef.current,
            { scale: 0.9, opacity: 0 },
            {
                scale: 1,
                opacity: 1,
                duration: 1.5,
                ease: "power4.out",
                scrollTrigger: {
                    trigger: sliderRef.current,
                    start: "top 80%",
                    toggleActions: "play none none none",
                },
            }
        );
    }, []);

    useEffect(() => {
        // Sequential Animations for Image and Text
        const ctx = gsap.context(() => {
            const timeline = gsap.timeline();

            // Image Animation
            timeline.fromTo(
                sliderRef.current.querySelector("img"),
                {
                    opacity: 0,
                    scale: 1.1,
                    rotation: 10,  // Adding rotation effect for a smooth visual
                    filter: "blur(5px)", // Start with a blur to create depth effect
                },
                {
                    opacity: 1,
                    scale: 1,
                    rotation: 0,  // Reset rotation to 0 for smoothness
                    filter: "blur(0)", // Remove blur for crisp effect
                    duration: 1.5,
                    ease: "power4.out",
                }
            );

            // Text Animation
            timeline.fromTo(
                textRef.current[0],
                { y: 50, opacity: 0 },
                { y: 0, opacity: 1, duration: 0.8, ease: "power4.out" },
                "-=1" // Overlap with image animation
            );
            timeline.fromTo(
                textRef.current[1],
                { y: 50, opacity: 0 },
                { y: 0, opacity: 1, duration: 0.8, ease: "power4.out" },
                "-=0.6" // Slight stagger
            );
        });

        return () => ctx.revert(); // Clean up animations
    }, [currentIndex]);

    return (
        <div
            ref={sliderRef}
            className="relative w-full h-screen overflow-hidden bg-black text-white flex items-center justify-center"
        >
            {/* Background Image */}
            <img
                src={slides[currentIndex].image}
                alt={slides[currentIndex].title}
                className="absolute inset-0 w-full h-full object-cover transform scale-105 transition-transform duration-700"
            />
            {/* Gradient Overlay */}
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-70"></div>

            {/* Text Content */}
            <div className="relative z-10 text-center px-8">
                <h2
                    ref={(el) => (textRef.current[0] = el)}
                    className="text-4xl lg:text-6xl font-bold uppercase mb-4"
                >
                    {slides[currentIndex].title}
                </h2>
                <p
                    ref={(el) => (textRef.current[1] = el)}
                    className="text-lg lg:text-xl max-w-3xl mx-auto"
                >
                    {slides[currentIndex].description}
                </p>
            </div>

            {/* Navigation Buttons */}
            <div className="absolute inset-0 flex justify-between items-center px-6 z-20 hidden md:flex">
                <button
                    onClick={handlePrev}
                    className="p-4 bg-white bg-opacity-30 text-black rounded-full hover:bg-opacity-50 transition-transform duration-300"
                    aria-label="Previous Slide"
                >
                    <ArrowCircleLeftIcon fontSize="large" />
                </button>
                <button
                    onClick={handleNext}
                    className="p-4 bg-white bg-opacity-30 text-black rounded-full hover:bg-opacity-50 transition-transform duration-300"
                    aria-label="Next Slide"
                >
                    <ArrowCircleRightIcon fontSize="large" />
                </button>
            </div>

            {/* Indicator Dots */}
            <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2 flex space-x-3 z-20">
                {slides.map((_, index) => (
                    <div
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                        className={`w-4 h-4 rounded-full cursor-pointer transition-all duration-300 ${index === currentIndex ? "bg-white scale-110" : "bg-gray-500"
                            }`}
                    ></div>
                ))}
            </div>
        </div>
    );
};

export default CustomSlider;
