import EnquiryForm from './EnquiryForm';
import ExclusiveBrand from './ExclusiveBrand';
import Hero from './Hero';
import Testimonials from './Testimonials';
import Variety from './Variety';
import Slider from './Slider';
import BrandSlider from './BrandSlider';
import img1 from '../../assets/images/kajaria/KAJARIA 1.webp';
import img2 from '../../assets/images/kajaria/KAJARIA 2.webp';
import img3 from '../../assets/images/kajaria/KAJARIA 3.jpg';
import prime1 from '../../assets/images/nexion/NEXION 1.jpg';
import prime2 from '../../assets/images/nexion/NEXION 2.jpeg';
import prime3 from '../../assets/images/nexion/NEXION 3.jpeg';
import bej1 from '../../assets/images/simpolo/SIMPOLO 1.jpg';
import bej2 from '../../assets/images/simpolo/SIMPOLO 2.jpg';
import bej3 from '../../assets/images/simpolo/SIMPOLO 3.jpg';
import StatsCounter from './StatsCounter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { IconButton } from '@mui/material';
import OurClients from './OurClients';
import FeatureCards from './FeatureCards';
import RoomVisualizer from './RoomVisualizer';
import EleganceShowcase from './EleganceShowcase';

const Home = () => {
    return (
        <>
            <Hero />
            <BrandSlider />
            <EleganceShowcase />
            <FeatureCards />
            <div id="explore">
                <Variety />
            </div>
            <Slider />
            {/* <RoomVisualizer/> */}
            <ExclusiveBrand
                brand={'NEXION'}
                firstDesc={' A modern synthesis between aesthetic richness and technical capabilities highlighted with the largest options of surface finishes, striving to obtain the right perceptive effect according to its own design context and willing to empower and give life to nature itself.'}

                secondDesc={"ITALIAN STYLE THAT INSPIRES"}
                img1={prime1}
                img2={prime2}
                img3={prime3}
                toggle={false}

            />
            <ExclusiveBrand
                brand={'KAJARIA'}
                firstDesc={'Kajaria brings to you premium tiles that are detailed with perfection. Each tile is made using state of the art technology making them strong, durable & easy to maintain.'}
                secondDesc={"INDIA'S NUMBER 1 TILES COMPANY"}
                img1={img1}
                img2={img2}
                img3={img3}
                toggle={true}
            />

            <ExclusiveBrand
                brand={'SIMPOLO'}
                firstDesc={'Simpolo Tiles has carved its niche as a consistently growing organisation with sheer innovation and passion rooted in simplicity.'}
                secondDesc={"WHERE INNOVATION IS THE NEW ART OF FASCINATION"}
                img1={bej1}
                img2={bej2}
                img3={bej3}
                toggle={false}

            />
            <StatsCounter />
            {/* <OurClients /> */}

            <IconButton
                component="a"
                href="https://wa.me/1234567890"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1000 }}
            >
                <WhatsAppIcon sx={{ color: '#006400', fontSize: '60px' }} />            </IconButton>
        </>
    );
}

export default Home;