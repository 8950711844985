import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchfiles } from '../../Redux/Slice/downloadSlice';
import { Link } from 'react-router-dom';

const Download = () => {
    const { fileData } = useSelector((state) => state.files);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchfiles());
    }, [dispatch]);

    // Function to handle file download
    const handleDownload = (file) => {
        const link = document.createElement('a');
        link.href = file;
        link.download = file;
        link.click();
    };

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col items-center pt-24">
            {/* Header Section */}
            <div className="container mx-auto text-center ">
                <p className="text-gray-600 text-sm font-sans uppercase tracking-wider mb-4">
                    <Link to='/'>
                        <span className="text-blue-500 font-semibold">
                            Home
                        </span>
                    </Link>
                    {` / `}
                    <span className="text-gray-700 font-semibold">
                        Downloads
                    </span>
                </p>
                <div className="w-full h-[1px] bg-gray-300 mb-6 mx-auto max-w-[150px] rounded-full"></div>
            </div>
            <header className="bg-gray-600 text-white w-full py-10 text-center shadow-lg">
                <h1 className="text-3xl font-extrabold tracking-wide">
                    Download Catalogs
                </h1>
                <p className="text-lg mt-2 opacity-90">
                    Explore and download our premium tile and marble collections.
                </p>
            </header>

            {/* File Cards Section */}
            <div className="p-8 md:p-16 lg:pt-20 w-full max-w-6xl">
                <div className="grid gap-8 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    {fileData.map((item) => (
                        <div
                            key={item.id}
                            onClick={() => handleDownload(item.catalog)}
                            className="bg-white border border-gray-200 shadow-md cursor-pointer hover:shadow-lg transition-transform transform hover:scale-105 duration-200 flex flex-col rounded-lg overflow-hidden"
                        >
                            {/* Image Section */}
                            <div className="w-full h-56 relative">
                                <img
                                    src={item.image}
                                    alt={item.title}
                                    className="object-cover h-full w-full"
                                />
                                <div className="absolute inset-0 bg-teal-600 bg-opacity-10 transition-opacity hover:bg-opacity-0" />
                            </div>

                            {/* Title Section */}
                            <div className="p-4 text-center flex-1">
                                <h3 className="text-base font-semibold text-gray-800">
                                    {item.title}
                                </h3>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Download;
