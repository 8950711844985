import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchProduct } from "../../Redux/Slice/GetProductSlice";
import { Link, useParams } from "react-router-dom";
import imgg from "../../assets/images/myImage.jpg";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const ProductList = () => {
    const { productsData } = useSelector((state) => state.products);
    const { id, name } = useParams();
    const dispatch = useDispatch();
    const categoryName = name;
    const productRefs = useRef([]);

    useEffect(() => {
        dispatch(fetchProduct(id));

        productRefs.current.forEach((product, index) => {
            gsap.fromTo(
                product,
                { opacity: 0, y: 20 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.8,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: product,
                        start: "top 90%",
                        toggleActions: "play none none none",
                    },
                }
            );
        });
    }, [dispatch, id]);

    return (
        <div style={{ backgroundColor: "#f9f9f9" }} className="py-12 p-5 pt-[5rem] md:p-32 lg:p-32 xl:p-32  min-h-screen text-center">
            <div className="container mx-auto text-center bg-gray-800 p-3 mb-4">
                <p className="text-gray-600 text-sm font-sans uppercase tracking-wider m-4">
                    <Link to='/'>
                        <span className="text-white font-semibold hover:text-blue-600">
                            Home
                        </span>
                    </Link>
                    {` / `}
                    <span className="text-white font-semibold">
                        {categoryName}
                    </span>
                </p>
                <div className="w-full h-[1px] bg-gray-300 mb-6 mx-auto max-w-[150px] rounded-full"></div>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 sm:gap-8 px-4 sm:px-6">
                {productsData.length > 0 && productsData.map((product, index) => (
                    <Link
                        to={`/product-list/sub_category/${product.id}/${encodeURIComponent(product.name)}/${id}`}
                        key={index}
                        className="bg-white border border-gray-300 shadow-lg overflow-hidden transition-all transform hover:scale-105 hover:shadow-2xl"
                        ref={(el) => (productRefs.current[index] = el)}
                    >
                        <div className="overflow-hidden">
                            <img
                                src={product.subcateimages}
                                alt={product.name}
                                className="w-full h-40 sm:h-48 object-cover transition-transform duration-300 ease-in-out hover:scale-110"
                            />
                        </div>
                        <div className="p-4 bg-gray-900">
                            <h3 className="sm:text-lg text-white text-2xl font-semibold truncate">
                                {product.name}
                            </h3>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default ProductList;
