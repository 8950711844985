import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllProduct, fetchProductDetails } from "../../Redux/Slice/GetProductSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Box, Card, CardContent, CardMedia, Typography, Button, Divider, Modal, TextField, Fade, Backdrop } from "@mui/material";
import { addUser } from "../../Redux/Slice/UseSlice";
import { addRequest } from "../../Redux/Slice/requestSlice";
import bottomBanner from '../../assets/images/bottombannerproduct.jpg';
import RelatedProductSlider from "./RelatedProductSlider";
import gsap from "gsap";
import { Breadcrumbs } from '@mui/material';
import { Home as HomeIcon, ArrowForward as ArrowIcon } from '@mui/icons-material';
import ImageSlider from "../../utils/ImageSlider";

const ProductDetail = () => {
    const navigate = useNavigate();
    const { productsData, AllProduct } = useSelector((state) => state.products);
    const { id, catId } = useParams();
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({ name: "", email: "", phone: "", qty: "", sku: "" });
    const category = AllProduct.find((category) => category.id === parseInt(catId));
    const [mainImage, setMainImage] = useState(productsData.ImageUrl);
    const mainImageRef = useRef(null);
    const [openSlider, setOpenSlider] = useState(false);  // Separate state for image slider
    const [selectedImages, setSelectedImages] = useState([]);

    const relatedProducts = category ? category.products.map(product => ({
        id: product.id,
        name: product.name,
        image: product.ImageUrl,
        catId,
    })) : [];

    // console.log('hey ' + JSON.stringify(relatedProducts))
    useEffect(() => {
        dispatch(fetchProductDetails(id));
        dispatch(fetchAllProduct())
    }, [dispatch, id]);

    useEffect(() => {
        setMainImage(productsData.ImageUrl)
        setSelectedImages([
            productsData.ImageUrl,
            productsData.ImageUrl2,
            productsData.ImageUrl3,
            productsData.ImageUrl4,
        ].filter(image => !image?.includes('/no_image.jpeg')))
    }, [productsData])

    const handleOpen = () => setModalOpen(true);
    const handleClose = () => {
        setModalOpen(false);
        setFormData({ name: "", email: "", phone: "", qty: "", sku: "" });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch(addRequest(formData));
        handleClose();
        alert(`Your request for ${formData.name} has been submitted. Our providers will reach you out within sometime`);

        setFormData({ name: "", email: "", phone: "", qty: "", sku: "" });
    };

    const handleImageClick = (image) => {
        setMainImage(image);
    };


    const handleBuy = () => {
        navigate('/contact');

        setTimeout(() => {
            const formSection = document.getElementById('place');
            if (formSection) {
                formSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        },);
    }
    const handleEnquire = () => {
        navigate('/contact');

        setTimeout(() => {
            const formSection = document.getElementById('enquiry-form');
            if (formSection) {
                formSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        },);
    }




    const handleOpenSlider = () => {
        setOpenSlider(true);
    };

    const handleCloseSlider = () => {
        setOpenSlider(false);
    };


    return (
        <>
            <div className="flex flex-col  pt-12 bg-white">
                <div className="container mx-auto text-center py-6 bg-gray-800 mt-10
                ">
                    <p className="text-white text-sm font-sans uppercase tracking-wider mb-4">
                        <Link to='/'>
                            <span className="text-blue-500 font-semibold">
                                Home
                            </span>
                        </Link>
                        {` / `}
                        <span className="text-white font-semibold">
                            {productsData.categoriename}
                        </span>
                        {` / `}
                        <span className="text-white font-semibold text-lg">
                            {productsData.brandname != 'KAJARIA' ? productsData.name : productsData.brandname}
                        </span>
                    </p>

                    <h2 className="text-3xl md:text-4xl font-bold text-white mt-2 mb-6">
                        {productsData.brandname != 'KAJARIA' ? productsData.name : productsData.brandname} ~ {productsData.size} ~ {productsData.finish}
                    </h2>

                    <div className="w-full h-[1px] bg-gray-300 mb-6 mx-auto max-w-[150px] rounded-full"></div>
                </div>


                <div style={{ backgroundColor: "#fff" }} className="flex flex-row justify-centerpb-8 min-h-screen px-4 md:px-6 items-center justify-center">
                    <div className="flex flex-col md:flex-row max-w-6xl w-full mt-6 space-y-6 md:space-y-0 md:space-x-10  rounded-lg overflow-hidden ">

                        {/* Left Side: Product Image */}
                        <div className="md:w-1/2 w-full flex flex-col items-center rounded-lg overflow-hidden">
                            {/* Main Image */}
                            <div className="w-full flex justify-center mb-3">
                                <img
                                    src={mainImage}
                                    alt="Main Product"
                                    className="w-[100%] h-[25rem] sm:h-[20rem] md:h-[25rem] rounded-lg object-cover border border-gray-300 shadow-md cursor-pointer"
                                    onClick={handleOpenSlider}
                                />
                            </div>
                            {/* <div className="overflow-hidden rounded-lg mb-6">
                                <img
                                    ref={mainImageRef}
                                    src={mainImage}
                                    alt="Main Product"
                                    className="w-full h-[25rem] sm:h-[20rem] md:h-[25rem] rounded-lg object-cover border border-gray-300 shadow-md transition-transform duration-300 hover:scale-105"
                                />
                            </div> */}

                            {/* ImageSlider Component */}
                            <ImageSlider
                                images={selectedImages}
                                openSlider={openSlider}
                                onCloseSlider={handleCloseSlider}
                            />

                            <div className="flex justify-center gap-4">
                                {[productsData.ImageUrl, productsData.ImageUrl2, productsData.ImageUrl3, productsData.ImageUrl4].filter(image => !image?.includes('/img/no_image.jpeg')).map(
                                    (image, index) => (
                                        <img
                                            key={index}
                                            src={image}
                                            alt={`Image ${index + 1}`}
                                            className="h-[4rem] w-[4rem] sm:h-[6rem] sm:w-[6rem] md:h-[7rem] md:w-[7rem] rounded-lg object-cover border border-gray-300 shadow-md transition-transform duration-300 hover:scale-110 cursor-pointer"
                                            onClick={() => handleImageClick(image)}
                                        />
                                    )
                                )}
                            </div>
                        </div>



                        {/* Right Side: Product Details */}
                        <div className="md:w-1/2 w-full p-8 flex flex-col space-y-6">
                            {/* Section Header */}
                            <div className="text-center mb-8">
                                <h2 className="text-3xl font-bold text-gray-800 uppercase tracking-wide">
                                    Product Info
                                </h2>
                                <p className="text-gray-500 text-sm mt-2">
                                    Here are the essential details about {productsData.categoriename}.
                                </p>
                            </div>

                            {/* Product Details */}
                            <div className="space-y-2 mb-[3rem]">
                                {productsData.brandname == 'KEROVIT' ? (
                                    <>
                                        {[
                                            { label: 'Brand', value: productsData.brandname },
                                            { label: 'SKU', value: productsData.sku },
                                            { label: 'Finish', value: productsData.finish },
                                            { label: 'Size', value: productsData.size },
                                            { label: 'UOM', value: productsData.uom },
                                            { label: 'Color', value: productsData.color || 'N/A' },
                                            { label: 'MRP', value: productsData.mrp },
                                        ].map((item, index) => (
                                            <div
                                                key={index}
                                                className="flex justify-between text-gray-700 border-b border-gray-200 pb-4"
                                            >
                                                <span className="font-semibold">{item.label}:</span>
                                                <span className="text-gray-600">{item.value}</span>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {[
                                            { label: 'Brand', value: productsData.brandname },
                                            { label: 'SKU', value: productsData.sku },
                                            { label: 'Finish', value: productsData.finish },
                                            { label: 'Size', value: productsData.size },
                                            { label: 'UOM', value: productsData.uom },
                                            { label: 'Color', value: productsData.color || 'N/A' },
                                        ].map((item, index) => (
                                            <div
                                                key={index}
                                                className="flex justify-between text-gray-700 border-b border-gray-200 pb-4"
                                            >
                                                <span className="font-semibold">{item.label}:</span>
                                                <span className="text-gray-600">{item.value}</span>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>



                            {/* Request Quote Button */}
                            <div className="flex justify-center gap-4 pt-[3rem]">
                                <button
                                    onClick={() => {
                                        handleOpen();
                                        setFormData({ name: "", email: "", phone: "", qty: "", sku: productsData.sku });
                                    }}
                                    className="relative overflow-hidden px-6 py-2 bg-white border border-black text-black font-semibold text-md transition-all duration-300 focus:outline-none group"
                                >
                                    <span className="absolute inset-0 bg-black transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0" />
                                    <span className="relative z-10 transition-colors duration-300 group-hover:text-white">Request Quote</span>
                                </button>
                                <button
                                    onClick={() => {
                                        handleBuy();
                                        setFormData({ name: "", email: "", phone: "", qty: "", sku: productsData.sku });
                                    }}
                                    className="relative overflow-hidden px-6 py-2 bg-white border border-black text-black font-semibold text-md transition-all duration-300 focus:outline-none group"
                                >
                                    <span className="absolute inset-0 bg-black transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0" />
                                    <span className="relative z-10 transition-colors duration-300 group-hover:text-white">Where To Buy</span>
                                </button>
                                <button
                                    onClick={() => {
                                        handleEnquire();
                                    }}
                                    className="relative overflow-hidden px-6 py-2 bg-white border border-black text-black font-semibold text-md transition-all duration-300 focus:outline-none group"
                                >
                                    <span className="absolute inset-0 bg-black transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0" />
                                    <span className="relative z-10 transition-colors duration-300 group-hover:text-white">Enquire Now</span>
                                </button>
                            </div>
                        </div>
                        {/* Product Description */}


                    </div>

                </div>
                <div className="bg-gray-50 rounded-lg lg:p-[10rem] lg:pt-[5rem] p-4 ">
                    <h3 className="text-xl font-semibold text-gray-800 uppercase mb-4">
                        Product Description
                    </h3>
                    <p className="text-gray-600 leading-relaxed">
                        {productsData.description !== 'title'
                            ? productsData.description
                            : 'A premium quality marble tile designed to elevate your interiors. Known for its durability, it’s the perfect choice for both residential and commercial spaces.'}
                    </p>
                </div>
                <RelatedProductSlider relatedProducts={relatedProducts} />
                <Modal
                    open={modalOpen}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: { timeout: 500 },
                    }}
                >
                    <Fade in={modalOpen}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: { xs: '90%', sm: '400px' },
                                bgcolor: '#ffffff',
                                borderRadius: 3,
                                boxShadow: 24,
                                p: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '2px solid #007bff',
                            }}
                        >
                            <Typography variant="h6" component="h2" sx={{ mb: 2, fontWeight: 'bold', color: '#007bff' }}>
                                Enquire
                            </Typography>
                            <form onSubmit={onSubmit} style={{ width: '100%' }}>
                                <TextField
                                    label="Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    fullWidth
                                    sx={{ mb: 2, '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
                                />
                                <TextField
                                    label="Email"
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    // required
                                    fullWidth
                                    sx={{ mb: 2, '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
                                />
                                <TextField
                                    label="Phone"
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                    fullWidth
                                    sx={{ mb: 2, '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
                                />
                                <TextField
                                    label="Quantity Required"
                                    type="text"
                                    name="qty"
                                    value={formData.qty}
                                    onChange={handleChange}
                                    required
                                    fullWidth
                                    sx={{ mb: 2, '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        bgcolor: '#007bff',
                                        '&:hover': { bgcolor: '#0056b3' },
                                        fontWeight: 'bold',
                                        borderRadius: '8px',
                                        transition: 'background-color 0.3s',
                                        width: '100%',
                                    }}
                                >
                                    Submit Request
                                </Button>
                            </form>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    );
};

export default ProductDetail;
