import React, { useState, useEffect } from 'react';
import video from '../../assets/5.mp4'; // Your video file
import { useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ReactTyped } from 'react-typed';

gsap.registerPlugin(ScrollTrigger);
// const colors = ['text-red-500', 'text-blue-500', 'text-green-500', 'text-yellow-500'];

const Hero = () => {
    const navigate = useNavigate();
    const [showAfter, setShowAfter] = useState(false);

    // const [currentColor, setCurrentColor] = useState(colors[0]);
    // const strings = ['Marble', 'Granite', 'Quartz', 'Stone'];
    // const typeSpeed = 70;
    // const backSpeed = 50;
    // const delay = (typeSpeed + backSpeed) * strings[0].length + 800;

    // useEffect(() => {
    //     let index = 0;

    //     const interval = setInterval(() => {
    //         index = (index + 1) % colors.length;
    //         setCurrentColor(colors[index]);
    //     }, delay);

    //     return () => clearInterval(interval);
    // }, [delay]);

    const handleRedirect = () => {
        navigate('/');
        setTimeout(() => {
            const formSection = document.getElementById('explore');
            if (formSection) {
                formSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 800);
    };

    useEffect(() => {
        // GSAP animation for the text
        gsap.fromTo(
            ".hero-text",
            { opacity: 0, y: 100 },
            {
                opacity: 1,
                y: 0,
                duration: 1.5,
                ease: "power4.out",
                scrollTrigger: {
                    trigger: ".hero-text",
                    start: "top 80%",
                    end: "bottom 20%",
                    toggleActions: "play none none none",
                },
            }
        );

        // GSAP animation for the button
        gsap.fromTo(
            ".explore-btn",
            { opacity: 0, scale: 1.2 },
            {
                opacity: 1,
                scale: 1,
                duration: 1.5,
                delay: 0.5,
                ease: "power4.out",
                scrollTrigger: {
                    trigger: ".explore-btn",
                    start: "top 80%",
                    end: "bottom 20%",
                    toggleActions: "play none none none",
                },
            }
        );
    }, []);

    return (
        <div className="relative h-screen overflow-hidden">
            {/* Full Background Video */}
            <video
                autoPlay
                loop
                muted
                className="absolute top-0 left-0 min-w-full min-h-full w-auto h-auto object-cover z-0"
            >
                <source src={video} type="video/mp4" />
                Your browser does not support the video.
            </video>

            {/* Dark overlay for better readability */}
            <div className="absolute inset-0 bg-black opacity-40 z-1"></div>

            {/* Hero Content */}
            <div className="relative z-10 flex flex-col items-center justify-center h-full text-white text-center p-4 hero-text">
                <h1 className="text-3xl md:text-5xl  lg:text-5xl   xl:text-6xl  font-serif   mb-2 uppercase tracking-tighter space-x-3 text-shadow-lg">
                    Elevate Your Space with Premium  INTERIOR
                </h1>
                <span className=" text-3xl md:text-4xl  lg:text-4xl  font-serif xl:text-4xl    mb-5 uppercase tracking-wide text-shadow-lg">
                    {/* <ReactTyped
                        className={`text-6xl font-extrabold mb-6 uppercase tracking-wide ${currentColor} text-shadow-lg`}
                        strings={strings}
                        typeSpeed={typeSpeed}
                        backSpeed={backSpeed}
                        backDelay={800}
                        loop
                    /> */}
                    LIFESTYLE PRODUCTS
                </span>
                <p className="mb-8 text-lg max-w-2xl  tracking-wide text-shadow-lg">
                    Discover exquisite collections that blends elegance and luxury to your interiors.
                </p>
                <button
                    onClick={handleRedirect}
                    className="relative overflow-hidden px-6 py-2 bg-white border-2 border-black hover:border-white text-black   transition-all duration-300 focus:outline-none group rounded-full"
                >
                    <span className="absolute inset-0 bg-black transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left" />

                    <span className="relative z-10 text-xl font-serif group-hover:text-white transition-all duration-300">Explore Our Collection</span>

                    <span className="absolute inset-0 border-2 border-transparent group-hover:border-black rounded-full transition-all duration-300" />
                </button>


            </div>

            {/* GSAP ScrollTrigger to animate background video */}
            <div className="absolute inset-0 z-0" style={{ transform: 'scale(1.1)' }}>
                <video
                    autoPlay
                    loop
                    muted
                    className="absolute top-0 left-0 min-w-full min-h-full w-auto h-auto object-cover z-0"
                    style={{ transform: 'scale(1.1)' }}
                >
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video.
                </video>
            </div>
        </div>
    );
};

export default Hero;
