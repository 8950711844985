import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const EleganceSection = () => {
    const leftColRef = useRef(null);
    const rightColRef = useRef(null);
    const sectionRef = useRef(null);

    useEffect(() => {
        // GSAP Animations for Left and Right Columns
        const ctx = gsap.context(() => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: sectionRef.current,
                    start: "top center",
                    end: "bottom center",
                    toggleActions: "play none none reverse",
                    scrub: true,
                },
            })
                .fromTo(
                    leftColRef.current,
                    { x: -60, opacity: 0, scale: 1.1 },
                    { x: 0, opacity: 1, scale: 1, duration: 1.5, ease: "power3.out" }
                )
                .fromTo(
                    rightColRef.current,
                    { x: 60, opacity: 0, scale: 1.1 },
                    { x: 0, opacity: 1, scale: 1, duration: 1.5, ease: "power3.out" },
                    "-=1"
                );
        });

        return () => ctx.revert();
    }, []);

    return (
        <section
            ref={sectionRef}
            className="flex flex-col lg:flex-row items-center justify-between bg-gradient-to-r from-gray-100 to-gray-300 p-10 lg:px-28 mb-[-2rem] shadow-lg overflow-hidden"
        >
            {/* Left Column: Title */}
            <div ref={leftColRef} className="lg:w-1/3 text-left ml-4 space-y-6 bottom-0">
                <h2 className="text-4xl lg:text-5xl text-gray-950 font-extrabold text-shadow-xl tracking-wide leading-snug">
                    ELEGANCE, LUXURY, <br />
                    GLAMOUR AND <br />
                    SENSUALITY
                </h2>
            </div>

            {/* Right Column: Content */}
            <div ref={rightColRef} className="relative lg:w-2/3 text-gray-800 text-left space-y-6 p-6 lg:p-8 pt-14 lg:pt-32 xl:pt-32 pb-4 flex">
                {/* Vertical Blue Line */}
                <div className="w-2 bg-blue-700"></div> {/* Adjusted line to be a child of flex container */}

                <div className="flex-1 pl-4"> {/* Added padding to separate text from the line */}
                    <p className="text-lg lg:text-xl font-medium opacity-90">
                        Immerse yourself in a world where elegance meets design excellence. MP Group's stunning collection
                        offers unique patterns and luxurious finishes that transform your spaces into showpieces.
                    </p>
                    <p className="text-lg lg:text-xl font-medium opacity-90">
                        Every product is a statement piece, bringing together beauty and durability, making it easy to create
                        spaces that reflect your sophisticated taste.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default EleganceSection;
