import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import store from './Redux/Store';
import { Provider } from 'react-redux';
import './App.css';
import Layout from './Layout/Layout';
import CategoryList from './Pages/Product/SubCategoryList';
import ProductList from './Pages/Product/ProductList';
import ProductDetail from './Pages/Product/ProductDetail';
import AddUser from './Pages/User/addUser';
import ProductSubcategory from './Pages/Product/ProductSubcategory';
import Home from './Pages/Home/Home';
import AboutUs from './Pages/About/AboutUs';
import Download from './Pages/Downloads/Download';
import Contact from './Pages/Contact/Contact';
import Showrooms from './Pages/Showrrom/Showrooms';
import ScrollToTop from './utils/ScrollToTop';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { IconButton } from '@mui/material';

function App() {
  const pulseKeyframes = {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.2)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  };
  return (
    <>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />} >
              <Route path="/" element={<Home />} />
              <Route path="/sub-category-list/:id/:name" element={<ProductList />} />
              <Route path="/product-list/:type/:id/:name/:catId" element={<ProductSubcategory />} />
              <Route path="/product-details/:id/:catId" element={<ProductDetail />} />
              <Route path="/about/us" element={<AboutUs />} />
              <Route path="/downloads" element={<Download />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/showrooms" element={<Showrooms />} />
              {/* <Route path="/categorylist" element={<CategoryList />} /> */}
              {/* <Route path="/add-user" element={<AddUser />} /> */}
            </Route>
          </Routes>
        </Router>
      </Provider>
      <IconButton
        component="a"
        href="https://wa.me/9779802021918"
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '70px',
          height: '70px',
          borderRadius: '50%',
          background: 'linear-gradient(45deg, #006400, #25D366)',
          boxShadow: '0 0 20px rgba(0, 100, 0, 0.5)',
          transform: 'scale(0.9)',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease',
          animation: 'bounce 1s infinite',
          '&:hover': {
            transform: 'scale(1)',
            boxShadow: '0 0 25px rgba(0, 100, 0, 0.7)',
          },
        }}
      >
        <WhatsAppIcon
          sx={{
            color: '#fff',
            fontSize: '40px',
            transition: 'color 0.3s ease',
            '&:hover': { color: '#25D366' },
          }}
        />
      </IconButton>

    </>
  );
}
export default App;