import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addEnquiry } from '../../Redux/Slice/enquirySlice';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const EnquiryForm = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        remarks: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addEnquiry(formData));
        console.log('Form submitted:', formData);
        setFormData({
            name: '',
            phone: '',
            email: '',
            remarks: '',
        });
        alert("Your query has been noted. Our experts will contact you soon!");
    };

    const formRef = useRef(null);

    useEffect(() => {
        // Register ScrollTrigger plugin
        gsap.registerPlugin(ScrollTrigger);

        // GSAP animation for the form
        gsap.fromTo(
            formRef.current,
            { y: 100, opacity: 0, scale: 0.9 }, // Initial state
            {
                y: 0,
                opacity: 1,
                scale: 1,
                duration: 1,
                ease: 'power2.out',
                scrollTrigger: {
                    trigger: formRef.current,
                    toggleActions: 'play none none reverse',
                },
            }
        );
    }, []);

    return (
        <div ref={formRef} className="max-w-5xl mx-auto p-6 bg-gray-100 rounded-xl shadow-md mt-8 border border-black">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {/* Left Section */}
                <div className="md:col-span-1 text-center md:text-left flex flex-col justify-center">
                    <h2 className="text-3xl font-bold text-gray-800 mb-3">
                        Book a Consultation
                    </h2>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        Transform your space with tiles and marbles that blend quality and elegance perfectly.
                    </p>
                    <p className="text-sm italic text-gray-500">
                        "Style and durability for every corner of your home."
                    </p>
                </div>

                {/* Form Section */}
                <div className="md:col-span-2 bg-white p-6 rounded-lg shadow-sm">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        {/* Input Fields */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-semibold text-gray-800 mb-2">
                                    Your Name
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-400 focus:border-gray-400"
                                    placeholder="Your Full Name"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-semibold text-gray-800 mb-2">
                                    Contact Number
                                </label>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-400 focus:border-gray-400"
                                    placeholder="+977 1234567890"
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-semibold text-gray-800 mb-2">
                                    Email Address
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-400 focus:border-gray-400"
                                    placeholder="example@mail.com"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-semibold text-gray-800 mb-2">
                                    Additional Remarks
                                </label>
                                <textarea
                                    name="remarks"
                                    value={formData.remarks}
                                    onChange={handleChange}
                                    rows="3"
                                    className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-400 focus:border-gray-400"
                                    placeholder="Tell us how we can assist you..."
                                ></textarea>
                            </div>
                        </div>

                        {/* Disclaimer */}
                        <p className="text-sm text-gray-500 text-center">
                            By submitting this form, you agree to be contacted by MP Group via phone, SMS, or email.
                        </p>

                        {/* Submit Button */}
                        <div className="text-center">
                            <button
                                type="submit"
                                className="bg-gray-800 text-white font-bold py-3 px-8 rounded-lg shadow-md hover:shadow-lg transition duration-300 focus:outline-none focus:ring-4 focus:ring-gray-600"
                            >
                                Submit Enquiry
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EnquiryForm;
