import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ExclusiveBrands = ({ brand, firstDesc, secondDesc, img1, img2, img3, toggle }) => {
    const images = [img1, img2, img3]; // List of all images
    const [selectedImage, setSelectedImage] = useState(img1); // State for the main image
    const containerRef = useRef(null); // Ref for the entire component
    const cardRef = useRef(null);
    const sliderRef = useRef(null);
    const leftRef = useRef(null);
    const rightRef = useRef(null);
    const imagesRef = useRef([]);

    useEffect(() => {
        // Smooth sliding in and fading for left section
        gsap.fromTo(
            leftRef.current,
            { opacity: 0, x: -150 },
            {
                opacity: 1,
                x: 0,
                duration: 1.2,
                ease: 'power3.out',
                delay: 0.3, // Slight delay for better sequencing
                scrollTrigger: {
                    trigger: leftRef.current,
                    start: 'top 85%',
                    end: 'top 60%',
                    scrub: 1, // Smoother scroll animation
                    toggleActions: 'play none none none',
                },
            }
        );

        // Smooth sliding in and fading for right section
        gsap.fromTo(
            rightRef.current,
            { opacity: 0, x: 150 },
            {
                opacity: 1,
                x: 0,
                duration: 1.2,
                ease: 'power3.out',
                delay: 0.5, // Slight delay to stagger with left section
                scrollTrigger: {
                    trigger: rightRef.current,
                    start: 'top 85%',
                    end: 'top 60%',
                    scrub: 1,
                    toggleActions: 'play none none none',
                },
            }
        );

        // Image hover scaling effect without blur
        gsap.utils.toArray(imagesRef.current).forEach((image) => {
            gsap.fromTo(
                image,
                { scale: 1 },
                {
                    scale: 1.05, // Slight zoom effect
                    duration: 0.3,
                    ease: 'ease-in-out',
                    paused: true,
                    scrollTrigger: {
                        trigger: image,
                        start: 'top 90%',
                        end: 'top 60%',
                        toggleActions: 'play none none none',
                    },
                }
            );
        });
    }, []);

    useEffect(() => {
        // Set up ScrollTrigger animations for the card and slider
        const timeline = gsap.timeline({
            scrollTrigger: {
                trigger: containerRef.current,
                start: "top 80%", // Animation starts when the component is 80% in view
                end: "top 20%", // Animation ends when it's 20% in view
                scrub: true, // Smooth scrubbing
            },
        });

        timeline
            .fromTo(
                cardRef.current,
                { x: toggle ? 200 : -200, opacity: 0 },
                { x: 0, opacity: 1, duration: 1, ease: "power3.out" }
            )
            .fromTo(
                sliderRef.current,
                { x: toggle ? -200 : 200, opacity: 0 },
                { x: 0, opacity: 1, duration: 1, ease: "power3.out" },
                "<" // Start this animation simultaneously
            );
    }, [toggle]);

    return (
        <>
            <div
                ref={containerRef}
                className="relative hidden md:flex flex-col items-center bg-gradient-to-l from-gray-50 to-gray-100 pl-28 pr-28 pt-44 pb-7  overflow-hidden"
            >
                {toggle ? (
                    <div className="relative flex flex-col md:flex-row items-center justify-center w-full max-w-screen-xl space-y-12 md:space-y-0">
                        {/* Main Image Section */}
                        <div
                            ref={sliderRef}
                            className="relative w-full md:w-2/3 flex flex-col items-center z-10 mr-[-3rem]"
                        >
                            <div className="relative w-full h-64 md:h-[400px] overflow-hidden rounded-xl shadow-lg">
                                <img
                                    src={selectedImage}
                                    alt={`${brand} main`}
                                    className="w-full h-full object-cover transition-all duration-500 ease-in-out "
                                />
                            </div>
                        </div>
                        {/* Content Card Section (Overlapping the Image Slider) */}
                        <div
                            ref={cardRef}
                            className="absolute md:relative top-[-50px] md:top-[-100px] w-11/12 md:w-[750px] border-2 border-gray-400 bg-white shadow-2xl p-6 md:p-10 lg:p-12 rounded-2xl transform transition duration-500 ease-in-out z-20"
                        >
                            <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4 md:mb-6">
                                {brand}
                            </h2>
                            <p className="text-gray-700 text-sm md:text-base mb-4">{firstDesc}</p>
                            <p className="text-gray-600 text-sm md:text-base">{secondDesc}</p>

                            {/* Image Selector Inside Content Card */}
                            <div className="flex space-x-3 mt-6 justify-center overflow-x-auto pb-4">
                                {images.map((image, index) => (
                                    <button
                                        key={index}
                                        onClick={() => setSelectedImage(image)}
                                        className={`w-12 h-12 md:w-16 md:h-16 rounded-lg overflow-hidden border-4 transition duration-300 ease-in-out ${selectedImage === image
                                            ? "border-black"
                                            : "border-gray-300 hover:border-gray-500"
                                            }`}
                                    >
                                        <img
                                            src={image}
                                            alt={`Thumbnail ${index + 1}`}
                                            className="w-full h-full object-cover rounded-lg"
                                        />
                                    </button>
                                ))}
                            </div>
                        </div>


                    </div>
                ) : (
                    <div className="relative flex flex-col md:flex-row items-center justify-center w-full max-w-screen-xl space-y-12 md:space-y-0">
                        {/* Content Card Section (Overlapping the Image Slider) */}
                        <div
                            ref={cardRef}
                            className="absolute md:relative top-[-50px] md:top-[-100px] w-11/12 md:w-[750px] border-2 border-gray-400 bg-white shadow-2xl p-6 md:p-10 lg:p-12 rounded-2xl transform transition duration-500 ease-in-out z-20 "
                        >
                            <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4 md:mb-6">
                                {brand}
                            </h2>
                            <p className="text-gray-700 text-sm md:text-base mb-4">{firstDesc}</p>
                            <p className="text-gray-600 text-sm md:text-base">{secondDesc}</p>

                            {/* Image Selector Inside Content Card */}
                            <div className="flex space-x-3 mt-6 justify-center overflow-x-auto pb-4">
                                {images.map((image, index) => (
                                    <button
                                        key={index}
                                        onClick={() => setSelectedImage(image)}
                                        className={`w-12 h-12 md:w-16 md:h-16 rounded-lg overflow-hidden border-4 transition duration-300 ease-in-out ${selectedImage === image
                                            ? "border-black"
                                            : "border-gray-300 hover:border-gray-500"
                                            }`}
                                    >
                                        <img
                                            src={image}
                                            alt={`Thumbnail ${index + 1}`}
                                            className="w-full h-full object-cover rounded-lg"
                                        />
                                    </button>
                                ))}
                            </div>
                        </div>

                        {/* Main Image Section */}
                        <div
                            ref={sliderRef}
                            className="relative w-full md:w-2/3 flex flex-col items-center z-10 ml-[-3rem]"
                        >
                            <div className="relative w-full h-64 md:h-[400px] overflow-hidden rounded-xl shadow-lg">
                                <img
                                    src={selectedImage}
                                    alt={`${brand} main`}
                                    className="w-full h-full object-cover transition-all duration-500 ease-in-out "
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>



            {/* for mobile */}
            <div className="block md:hidden w-full px-6 py-10 bg-gradient-to-r from-gray-900 to-black overflow-hidden">
                {toggle ? (
                    <>

                        <div ref={leftRef} className="w-full md:w-1/2 text-white space-y-6 max-w-lg">
                            <h2 className="text-6xl font-extrabold tracking-tight">{brand}</h2>
                            <p className="text-3xl">{secondDesc}</p>
                            <p className="text-lg">{firstDesc}</p>
                        </div>

                        {/* Right Section - Images */}
                        <div ref={rightRef} className="w-full grid grid-cols-3 mt-5 gap-3">
                            <div
                                ref={(el) => (imagesRef.current[0] = el)}
                                className="image-card relative group overflow-hidden rounded-2xl shadow-2xl"
                            >
                                <img
                                    src={img1}
                                    alt="Image 1"
                                    className="w-full h-full object-cover group-hover:scale-110 transition duration-300 ease-in-out"
                                />
                            </div>
                            <div
                                ref={(el) => (imagesRef.current[1] = el)}
                                className="image-card relative group overflow-hidden rounded-2xl shadow-2xl"
                            >
                                <img
                                    src={img2}
                                    alt="Image 2"
                                    className="w-full h-full object-cover group-hover:scale-110 transition duration-300 ease-in-out"
                                />
                            </div>
                            <div
                                ref={(el) => (imagesRef.current[2] = el)}
                                className="image-card relative group overflow-hidden rounded-2xl shadow-2xl"
                            >
                                <img
                                    src={img3}
                                    alt="Image 3"
                                    className="w-full h-full object-cover group-hover:scale-110 transition duration-300 ease-in-out"
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div ref={rightRef} className="w-full md:w-1/2 text-white space-y-6 max-w-lg">
                            <h2 className="text-6xl font-extrabold tracking-tight">{brand}</h2>
                            <p className="text-3xl">{secondDesc}</p>
                            <p className="text-lg">{firstDesc}</p>
                        </div>

                        {/* Right Section - Images */}
                        <div ref={leftRef} className="w-full grid grid-cols-3 mt-5 gap-3">
                            <div
                                ref={(el) => (imagesRef.current[0] = el)}
                                className="image-card relative group overflow-hidden rounded-2xl shadow-2xl"
                            >
                                <img
                                    src={img1}
                                    alt="Image 1"
                                    className="w-full h-full object-cover group-hover:scale-110 transition duration-300 ease-in-out"
                                />
                            </div>
                            <div
                                ref={(el) => (imagesRef.current[1] = el)}
                                className="image-card relative group overflow-hidden rounded-2xl shadow-2xl"
                            >
                                <img
                                    src={img2}
                                    alt="Image 2"
                                    className="w-full h-full object-cover group-hover:scale-110 transition duration-300 ease-in-out"
                                />
                            </div>
                            <div
                                ref={(el) => (imagesRef.current[2] = el)}
                                className="image-card relative group overflow-hidden rounded-2xl shadow-2xl"
                            >
                                <img
                                    src={img3}
                                    alt="Image 3"
                                    className="w-full h-full object-cover group-hover:scale-110 transition duration-300 ease-in-out"
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>

    );
};

export default ExclusiveBrands;
