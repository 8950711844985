import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix Leaflet default icon paths
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const MapComponent = () => {
    const dummyData = [
        { id: 1, latitude: 27.6844179, longitude: 85.3671769, name: "MARBLE PLAZA PVT. LTD." },
        { id: 2, latitude: 27.6866697, longitude: 85.3549496, name: "MARBLE PALACE INDUSTRIES PVT. LTD." },
        { id: 3, latitude: 27.6876052, longitude: 85.3505578, name: "MARBLE PALACE INDUSTRIES PVT. LTD." },
        { id: 4, latitude: 27.7069903, longitude: 85.3784401, name: "INDRENI MARBLE PLAZA PVT. LTD." },
        { id: 5, latitude: 27.6820904, longitude: 85.3553724, name: "(1) INDRENI MARBLE PLAZA PVT. LTD.", name2: "(2) KAJARIA ETERNITY WORLD", name3: "(3) NEXION STUDIO" },
        { id: 6, latitude: 27.6820904, longitude: 85.3553724, name: "INDRENI MARBLE PLAZA PVT. LTD." },
        { id: 7, latitude: 27.6820904, longitude: 85.3553724, name: "KAJARIA ETERNITY WORLD" },
        { id: 8, latitude: 27.6820904, longitude: 85.3553724, name: "NEXION STUDIO", },
    ];

    return (
        <div className="relative h-[75vh] w-full rounded-xl overflow-hidden shadow-xl">
            {/* Map Container */}
            <MapContainer
                center={[27.6940729, 85.306763]}
                zoom={13}
                className="h-full w-full z-0"
            >
                {/* Tile Layer */}
                <TileLayer
                    url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {/* Markers */}
                {dummyData.filter(item => ![6, 7, 8].includes(item.id)).map((location) =>
                    location.latitude !== 0 && location.longitude !== 0 ? (
                        <Marker key={location.id} position={[location.latitude, location.longitude]}>
                            <Popup>
                                <div className="text-center">
                                    <h3 className="font-bold text-sm text-gray-800">{location.name}</h3>
                                    <h3 className="font-bold text-sm text-gray-800">{location.name2 ? location.name2 : ''}</h3>
                                    <h3 className="font-bold text-sm text-gray-800">{location.name3 ? location.name3 : ''}</h3>

                                </div>
                            </Popup>
                        </Marker>
                    ) : null
                )}
            </MapContainer>
            <div className="absolute top-4 right-4 bg-white shadow-lg rounded-lg p-4 z-10 max-h-80 overflow-y-auto">
                <h2 className="text-lg font-semibold text-gray-800">Showrooms</h2>
                <ul className="list-disc list-inside text-gray-600">
                    {dummyData.filter(item => ![5].includes(item.id)).map((showroom) => (
                        <li key={showroom.id}>{showroom.name}</li>
                    ))}
                </ul>
            </div>

            {/* Overlay Effect */}
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/30 to-black/50 pointer-events-none"></div>

            {/* Glow Animation */}
            <div className="absolute top-0 left-0 h-10 w-10 rounded-full bg-blue-400/70 blur-2xl animate-ping"></div>
        </div>
    );
};

export default MapComponent;
