
import MapComponent from './MapComponent';



const Showrooms = () => {

    return (
        <div className='mt-20'>
            <MapComponent />
        </div>
    );
};

export default Showrooms;
