import React from 'react';
import { AppBar, Toolbar, Typography, Box, Grid, Button } from '@mui/material';
import { Instagram, Facebook, Twitter, LinkedIn, YouTube, WhatsApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import OurClients from '../Pages/Home/OurClients';
import { Link } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
    const { products } = useSelector((state) => state.category);
    const filteredProducts = products.filter(product => product.products_count > 0);
    const handleRedirect = () => {
        navigate('/'); // Redirect to Home page
        // After navigation, scroll to the Enquiry Form section
        setTimeout(() => {
            const formSection = document.getElementById('explore');
            if (formSection) {
                formSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        },);
    };
    const handleRedirectToForm = () => {
        navigate('/contact'); // Redirect to Home page
        // After navigation, scroll to the Enquiry Form section
        setTimeout(() => {
            const formSection = document.getElementById('enquiry-form');
            if (formSection) {
                formSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        },);
    };


    const sortedProducts = filteredProducts.sort((a, b) => {
        const priorityKeywords = [
            "NATURAL STONES", "TILES", "ARTIFICIAL QUARTZ ", "BATHWARE", "SANITARYWARE", "LAMINATE", "FLOORTILE", "TILE ADHESIVE"
        ];
        const aStartsWithPriority = priorityKeywords.some(keyword => a.name.startsWith(keyword));
        const bStartsWithPriority = priorityKeywords.some(keyword => b.name.startsWith(keyword));

        if (aStartsWithPriority && !bStartsWithPriority) {
            return -1; // a comes before b
        } else if (!aStartsWithPriority && bStartsWithPriority) {
            return 1; // b comes before a
        } else {
            return 0; // No change in order if both or neither start with the priority keywords
        }
    });

    return (
        <>
            <OurClients />
            <Box sx={{ backgroundColor: '#f5f5f5', color: '#333', py: 6, px: 4 }}>
                <Grid container spacing={3} justifyContent="center">
                    {/* Contact Information Section */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                            MP Group Nepal
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            Kathmandu | Bhaktapur | Banepa
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            <strong>+977-1-4990052 | 4112245 | 6637119 | 5919918</strong>
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            Email: <Link href="Marble2068@gmail.com" underline="hover">Marble2068@gmail.com</Link>
                        </Typography>
                    </Grid>

                    {/* Quick Links Section */}
                    <Grid item xs={12} sm={6} md={2}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                            Quick Links
                        </Typography>
                        <>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <Link to="/downloads" underline="hover" sx={{ color: '#333', cursor: "pointer" }}>
                                    Downloads
                                </Link>
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <Link onClick={handleRedirect} underline="hover" sx={{ color: '#333', cursor: "pointer" }}>
                                    Where to Buy
                                </Link>
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <Link to="/about/us" underline="hover" sx={{ color: '#333', cursor: "pointer" }}>
                                    About Us
                                </Link>
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <Link to="/showrooms" underline="hover" sx={{ color: '#333', cursor: "pointer" }}>
                                    Contact
                                </Link>
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <Link to="/showrooms" underline="hover" sx={{ color: '#333', cursor: "pointer" }}>
                                    Our Showrooms
                                </Link>
                            </Typography>
                        </>
                    </Grid>

                    {/* Products Section */}
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                            Products
                        </Typography>
                        {sortedProducts.slice(0, 6).map((product, index) => (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <Link
                                    to={product.subcategories.length > 0
                                        ? `/sub-category-list/${product.id}/${encodeURIComponent(product.name)}`
                                        : `/product-list/category/${product.id}/${encodeURIComponent(product.name)}/${product.id}`}
                                    key={index}
                                    underline="hover" sx={{ color: '#333', cursor: "pointer" }}
                                    className=""
                                >
                                    {product.name.charAt(0).toUpperCase() + product.name.slice(1).toLowerCase()}
                                </Link>
                            </Typography>
                        ))}
                    </Grid>

                    {/* Social Media Section */}
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                            Follow Us
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                            <Link
                                to="https://www.instagram.com/mp_nepal/"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 40,
                                    height: 40,
                                    borderRadius: '50%',
                                    backgroundColor: '#333',
                                    color: '#fff',
                                    transition: 'background-color 0.3s',
                                    '&:hover': { backgroundColor: '#f97316' },
                                }}
                            >
                                <Instagram fontSize="small" />
                            </Link>
                            <Link
                                to="https://www.facebook.com/marblepalacenepal?mibextid=ZbWKwL"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 40,
                                    height: 40,
                                    borderRadius: '50%',
                                    backgroundColor: '#333',
                                    color: '#fff',
                                    transition: 'background-color 0.3s',
                                    '&:hover': { backgroundColor: '#f97316' },
                                }}
                            >
                                <Facebook fontSize="small" />
                            </Link>
                            <Link
                                to="https://wa.me/9779802021918" sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 40,
                                    height: 40,
                                    borderRadius: '50%',
                                    backgroundColor: '#333',
                                    color: '#fff',
                                    transition: 'background-color 0.3s',
                                    '&:hover': { backgroundColor: '#f97316' },
                                }}
                            >
                                <WhatsApp fontSize="small" />
                            </Link>
                        </Box>
                        <button
                            variant="outlined"

                            onClick={handleRedirectToForm}
                            className="relative overflow-hidden px-6 py-2 bg-white border border-black text-black font-semibold text-md transition-all duration-300 focus:outline-none group"
                        >
                            <span className="absolute inset-0 bg-black transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0" />
                            <span className="relative z-10 transition-colors duration-300 group-hover:text-white">Submit Enquiry</span>
                        </button>
                    </Grid>
                </Grid>

                {/* Bottom Footer Section */}
                <Box sx={{ mt: 4, pt: 2, borderTop: '1px solid #ccc', textAlign: 'center' }}>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                        © {new Date().getFullYear()} MP Group Nepal. All rights reserved.
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#666' }}>
                        Made by <span style={{ fontWeight: 'bold', color: '#333' }}> <Link
                            to="https://ruminateitsolution.com/" sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 40,
                                height: 40,
                                borderRadius: '50%',
                                backgroundColor: '#333',
                                color: '#fff',
                                transition: 'background-color 0.3s',
                                '&:hover': { backgroundColor: '#f97316' },
                            }}
                        >Ruminate IT Solution</Link></span>
                    </Typography>
                </Box>
            </Box >
        </>
    );
};

export default Footer;
