import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, Divider, Box, Stack, ListItemText, Backdrop } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchproductcategory } from "../Redux/Slice/GetCategorySlice";
import logo from '../assets/images/png2.png';
import { FaAngleDown } from "react-icons/fa";
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchSearchProduct } from "../Redux/Slice/GetProductSlice";
import SearchAutocomplete from "../utils/SearchAutocomplete";

const Navbars = () => {
    const [megaDropdownOpen, setMegaDropdownOpen] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isDropdownHovered, setIsDropdownHovered] = useState(false);
    const [nestedDrawerOpen, setNestedDrawerOpen] = useState(false);
    const [scrollDirection, setScrollDirection] = useState("up");
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isVisible, setIsVisible] = useState(true);

    const dispatch = useDispatch();
    const { products } = useSelector((state) => state.category);
    const filteredProducts = products.filter(product => product.products_count > 0);

    React.useEffect(() => {
        dispatch(fetchproductcategory());
    }, [dispatch]);

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [dropdownCloseTimeout, setDropdownCloseTimeout] = useState(null);

    const handleMouseEnter = () => {
        if (dropdownCloseTimeout) {
            clearTimeout(dropdownCloseTimeout);
        }
        setMegaDropdownOpen(true);
    };


    const handleMouseLeave = () => {
        const timeout = setTimeout(() => {
            setMegaDropdownOpen(false);
        }, 200);
        setDropdownCloseTimeout(timeout);
    };



    const StyledLink = styled(RouterLink)(({ theme }) => ({
        position: 'relative',
        display: 'inline-block',
        color: theme.palette.info.main,
        textDecoration: 'none',
        '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: 0,
            height: '2px',
            width: '60%',
            backgroundColor: theme.palette.primary.main,
            transform: 'scaleX(0)',
            transition: 'transform 0.3s ease',
            transformOrigin: 'right',
        },
        '&:hover::after': {
            transform: 'scaleX(1)',
            transformOrigin: 'left',
        },
    }));

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;

            // Determine scroll direction (down or up)
            if (currentScrollPos > lastScrollTop) {
                // Scroll down
                setScrollDirection("down");
                if (isVisible) {
                    setIsVisible(false); // Hide navbar
                }
            } else {
                // Scroll up
                setScrollDirection("up");
                if (!isVisible) {
                    setIsVisible(true); // Show navbar
                }
            }

            setLastScrollTop(currentScrollPos <= 0 ? 0 : currentScrollPos);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollTop, isVisible]);

    const sortedProducts = filteredProducts.sort((a, b) => {
        const priorityKeywords = [
            "Marble", "Granite", "Tiles", "Quartz", "Kota Stone", "Bathware", "Sanitaryware",
            "Bathroom Accessories",
            "Shower", "Thermostat", "Laminate Floor", "Tile Adhesive", "Grouts", "Handmade Sink", "Appliances",
        ];

        // Helper function to get the priority index of a product name         
        const getPriorityIndex = (name) => {
            // Convert the name to lowercase for case-insensitive comparison
            const nameLower = name.toLowerCase();

            // Find the first keyword that matches the start of the product name
            for (let i = 0; i < priorityKeywords.length; i++) {
                if (nameLower.startsWith(priorityKeywords[i].toLowerCase())) {
                    return i;
                }
            }

            // If no match, return a value beyond the range of the priorityKeywords array
            return priorityKeywords.length;
        };

        const aPriority = getPriorityIndex(a.name);
        const bPriority = getPriorityIndex(b.name);

        // Compare based on priority
        return aPriority - bPriority;
    });


    return (
        <div className={`fixed z-40 w-[94%] sm:w-[98%] lg:w-[98%]   pl-5 pr-5  rounded-full m-3 bg-black bg-opacity-50 backdrop-blur-xl text-white shadow-2xl  ease-in-out ${isVisible ? "top-0" : "-top-96"
            }`}>
            <AppBar position="sticky" color="transparent" elevation={0} className="rounded-lg">
                <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography component="div" className="flex items-center  gap-[6rem]">
                        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}><img src={logo} className="h-14 w-14 scale-150 " /></Link>
                        <span className="w-[35vw] hidden lg:flex">
                            <SearchAutocomplete />
                        </span>
                    </Typography>


                    {/* Navbar Links */}
                    <Stack direction="row" spacing={3} sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
                        <Link to="/" className="nav-link">Home</Link>
                        <div
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className="nav-link"
                        >
                            <span className="cursor-pointer flex items-center gap-2">Products <FaAngleDown className="text-gray-400" /></span>
                            {megaDropdownOpen && (
                                <Box
                                    onMouseEnter={() => setIsDropdownHovered(true)}
                                    onMouseLeave={() => {
                                        setIsDropdownHovered(false);
                                        setMegaDropdownOpen(false);
                                    }}
                                    className="mega-dropdown"
                                    sx={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                        top: "100%",
                                        p: 4,
                                        boxShadow: 3,
                                        display: "grid",
                                        gridTemplateColumns: "repeat(4, 1fr)",  // Create 4 equal columns
                                        zIndex: 10,
                                        borderRadius: 10,
                                        backgroundColor: "#121212", // Dark background
                                        opacity: 1,
                                        transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
                                        transform: "translateY(10px)", // Slight drop to make it feel interactive
                                        gap: 2,  // Space between items
                                    }}
                                >
                                    {sortedProducts.map((product, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "flex-start",
                                                padding: 2,
                                                borderRadius: 8,
                                                transition: "all 0.3s ease-in-out",

                                            }}
                                        >
                                            <Stack spacing={1} onClick={() => setMegaDropdownOpen(false)}>
                                                {product.subcategories.length > 0 ? (
                                                    <StyledLink
                                                        key={index}
                                                        to={`/sub-category-list/${product.id}/${encodeURIComponent(product.name)}`}
                                                        style={{
                                                            color: "white",
                                                            fontSize: "16px",
                                                            fontWeight: 600,
                                                            textDecoration: "none",
                                                            transition: "color 0.3s ease",
                                                            lineHeight: "1.5",
                                                            display: "block",
                                                        }}
                                                    >
                                                        {product.name}
                                                    </StyledLink>
                                                ) : (
                                                    <StyledLink
                                                        key={index}
                                                        to={`/product-list/category/${product.id}/${encodeURIComponent(product.name)}/${product.id}`}
                                                        style={{
                                                            color: "white",
                                                            fontSize: "16px",
                                                            fontWeight: 600,
                                                            textDecoration: "none",
                                                            transition: "color 0.3s ease",
                                                            lineHeight: "1.5",
                                                            display: "block",
                                                        }}
                                                    // onMouseEnter={(e) => e.target.style.color = "#FFB400"}
                                                    // onMouseLeave={(e) => e.target.style.color = "white"}
                                                    >
                                                        {product.name}
                                                    </StyledLink>
                                                )}
                                            </Stack>
                                        </Box>
                                    ))}
                                </Box>
                            )}

                        </div>

                        <Link to="/downloads" className="nav-link">Downloads</Link>
                        <Link to="/about/us" className="nav-link">About</Link>
                        {/* <Link to="/showrooms" className="nav-link">Our Showrooms</Link> */}
                        <Link to="/contact" className="nav-link">Contact</Link>
                    </Stack>


                    {/* Drawer Toggle Button (hidden on larger screens) */}
                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleDrawerToggle}
                        sx={{ display: { xs: "block", md: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>

                {/* Drawer for Mobile */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: 250,
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            backdropFilter: 'blur(10px)',
                            padding: '20px',
                            // borderRadius: '8px',
                        },
                    }}
                >
                    <List sx={{ marginTop: '20px' }}>
                        <span>
                            <SearchAutocomplete />
                        </span>
                        <ListItem className=" mt-10" button onClick={handleDrawerToggle}>
                            <StyledLink to="/" style={{ color: 'white', textDecoration: 'none', width: '100%' }}>
                                <ListItemText primary="Home" />
                            </StyledLink>
                        </ListItem>
                        <ListItem button onClick={() => setNestedDrawerOpen(true)}>
                            <ListItemText style={{ color: 'white', textDecoration: 'none', width: '100%' }} primary="Products" />
                            <FaAngleDown className="text-white mr-11" />
                        </ListItem>
                        <ListItem button onClick={handleDrawerToggle}>
                            <StyledLink to="/downloads" style={{ color: 'white', textDecoration: 'none', width: '100%' }}>
                                <ListItemText primary="Downloads" />
                            </StyledLink>
                        </ListItem>
                        <ListItem button onClick={handleDrawerToggle}>
                            <StyledLink to="/about/us" style={{ color: 'white', textDecoration: 'none', width: '100%' }}>
                                <ListItemText primary="About" />
                            </StyledLink>
                        </ListItem>
                        {/* <ListItem button onClick={handleDrawerToggle}>
                            <StyledLink to="/showrooms" style={{ color: 'white', textDecoration: 'none', width: '100%' }}>
                                <ListItemText primary="Our Showrooms" />
                            </StyledLink>
                        </ListItem> */}
                        <ListItem button onClick={handleDrawerToggle}>
                            <StyledLink to="/contact" style={{ color: 'white', textDecoration: 'none', width: '100%' }}>
                                <ListItemText primary="Contact" />
                            </StyledLink>
                        </ListItem>

                    </List>
                </Drawer>

                <Drawer
                    variant="temporary"
                    open={nestedDrawerOpen}
                    onClose={() => { setNestedDrawerOpen(false); setMobileOpen(false); }}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: 250,
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            backdropFilter: 'blur(10px)',
                            padding: '20px',
                            // borderRadius: '8px',
                        },
                    }}
                >
                    <List>
                        <ListItem button onClick={() => setNestedDrawerOpen(false)}>
                            <ArrowBackIcon sx={{ left: '-10px', color: "#fff", font: 'bold', fontSize: '35px' }} />
                        </ListItem>
                        {sortedProducts.map((product, index) => (
                            <ListItem button key={index} >
                                {product.subcategories.length > 0 ? (
                                    <StyledLink
                                        to={`/sub-category-list/${product.id}/${encodeURIComponent(product.name)}`}
                                        style={{ color: 'white', textDecoration: 'none' }}
                                        onClick={() => {
                                            setNestedDrawerOpen(false);
                                            setMobileOpen(false);
                                        }}
                                    >
                                        <ListItemText primary={product.name} />
                                    </StyledLink>
                                ) : (
                                    <StyledLink
                                        to={`/product-list/category/${product.id}/${encodeURIComponent(product.name)}/${product.id}`}
                                        style={{ color: 'white', textDecoration: 'none' }}
                                        onClick={() => {
                                            setNestedDrawerOpen(false);
                                            setMobileOpen(false);

                                        }}
                                    >
                                        <ListItemText primary={product.name} />
                                    </StyledLink>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            </AppBar>
        </div>
    );
};

export default Navbars;
