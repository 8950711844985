import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Autocomplete, CircularProgress, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { fetchSearchProduct } from "../Redux/Slice/GetProductSlice";
import { Link } from "react-router-dom";

const SearchAutocomplete = () => {
    const [query, setQuery] = useState("");
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const { searchData, loading } = useSelector((state) => state.products);

    useEffect(() => {
        if (query.length > 0) {
            dispatch(fetchSearchProduct(query));
        }
    }, [query, dispatch]);

    return (
        <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={searchData || []}
            // getOptionLabel={(option) => option.name || ""}
            getOptionLabel={(option) => option.brandname?.toLowerCase() === 'kajaria' ? option.brandname : option.name}
            filterOptions={(x) => x}
            loading={loading}
            onInputChange={(e, value) => setQuery(value)}
            sx={{
                width: "100%",
                maxWidth: 400,
                mx: "auto",
                "& .MuiAutocomplete-inputRoot": {
                    backgroundColor: "#f9f9f9",
                    borderRadius: "9999px",
                    padding: "0px 12px",
                    height: "45px",
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                    },
                },
                "& .MuiInputBase-root": {
                    fontSize: "14px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                },
                "& .MuiAutocomplete-popupIndicator": {
                    display: "none",
                },
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Search products..."
                    variant="outlined"
                    className=""
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                <InputAdornment position="end">
                                    {query.length === 0 && (
                                        <SearchIcon
                                            sx={{
                                                color: "gray",
                                                cursor: "pointer",
                                                fontSize: "22px",
                                            }}
                                        />
                                    )}
                                </InputAdornment>
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderOption={(props, option) => (
                <Link
                    to={`/product-details/${option.id}/${option.category_id}`}
                >
                    <li
                        {...props}
                        key={option.id}
                        className="flex items-center p-2 hover:bg-gray-100 rounded-md cursor-pointer transition-all duration-200"
                    >
                        <div className="flex-grow text-sm font-medium">
                            {option.brandname?.toLowerCase() === 'kajaria'
                                ? `${option.brandname} - ${option.sku}`
                                : option.name}
                        </div>
                    </li>
                </Link>
            )}
        />

    );
};

export default SearchAutocomplete;
