import React, { useState } from 'react';
import { Modal, Backdrop, Fade } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, Close } from '@mui/icons-material';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

const ImageSlider = ({ images, openSlider, onCloseSlider }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handleNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentImageIndex(
            (prevIndex) => (prevIndex - 1 + images.length) % images.length
        );
    };

    return (
        <Modal
            open={openSlider}
            onClose={onCloseSlider} // Ensure this is correctly passed from the parent component
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{ backdrop: { timeout: 500 } }}
        >
            <Fade in={openSlider}>
                <div className="w-full h-full flex justify-center items-center bg-black bg-opacity-90 relative">
                    {/* Close Button */}
                    <button
                        onClick={onCloseSlider} // This should properly close the slider
                        className="absolute top-4 right-8 text-white text-4xl hover:opacity-80 z-10"
                    >
                        <CloseFullscreenIcon fontSize="large" />
                    </button>

                    {/* Image and Arrows */}
                    <div className="relative w-full h-full flex items-center justify-center">
                        {/* Left Arrow */}
                        <button
                            onClick={handlePrev}
                            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-5xl hover:opacity-80 z-10"
                        >
                            <ArrowBackIos fontSize="large" />
                        </button>

                        <img
                            src={images[currentImageIndex]}
                            alt={`Image ${currentImageIndex + 1}`}
                            className="w-auto h-[80vh] object-contain"
                        />

                        {/* Right Arrow */}
                        <button
                            onClick={handleNext}
                            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-5xl hover:opacity-80 z-10"
                        >
                            <ArrowForwardIos fontSize="large" />
                        </button>
                    </div>

                    {/* Thumbnails */}
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-3 items-center justify-center">
                        {images.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Thumbnail ${index + 1}`}
                                className={`w-[60px] h-[60px] rounded-md cursor-pointer border-2 ${currentImageIndex === index
                                    ? 'border-blue-500 scale-110'
                                    : 'border-white opacity-75'
                                    } transition-transform duration-300`}
                                onClick={() => setCurrentImageIndex(index)}
                            />
                        ))}
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default ImageSlider;
