import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { gsap } from "gsap";

const StatsCounter = () => {
    const { ref, inView } = useInView({
        threshold: 0.2,
    });
    const [hasStarted, setHasStarted] = useState(false);

    useEffect(() => {
        if (inView && !hasStarted) {
            setHasStarted(true);
            gsap.fromTo(
                ".stat-card",
                { opacity: 0, y: 50 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1.2,
                    ease: "power3.out",
                    stagger: 0.2,
                }
            );
        }
    }, [inView, hasStarted]);

    const stats = [
        { end: 40, label: "Brand Partners", color: "#3498db" },
        { end: 400, label: "Varieties", color: "#2ecc71" },
        { end: 1800, label: "Exclusive Products", color: "#e74c3c" },
    ];

    return (
        <div
            ref={ref}
            className="py-16 px-8   rounded-lg  lg:m-32 lg:mt-8 lg:mb-20"
        >
            <Grid container spacing={4} justifyContent="center">
                {stats.map((stat, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <div
                            style={{
                                textAlign: "center",
                                padding: "2rem 1rem",  // Padding for each stat
                                borderBottom: `2px solid ${stat.color}`,  // Adding border for separation
                                marginBottom: "1rem",  // Space between stats
                                transition: "transform 0.3s, box-shadow 0.3s",
                                "&:hover": {
                                    transform: "scale(1.05)",  // Slight scaling on hover
                                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",  // Shadow effect on hover
                                },
                            }}
                        >
                            <Typography
                                variant="h2"
                                component="div"
                                style={{
                                    color: stat.color,
                                    fontWeight: 700,
                                    marginBottom: "0.5rem",
                                    fontSize: "2rem",  // Adjust font size for smaller stats
                                }}
                            >
                                {hasStarted ? (
                                    <CountUp start={0} end={stat.end} duration={2.5} />
                                ) : (
                                    0
                                )}
                                +
                            </Typography>

                            <Typography
                                variant="h6"
                                style={{
                                    color: "#555555",
                                    fontWeight: 600,
                                    textTransform: "uppercase",
                                    letterSpacing: "0.5px",  // Reduced letter spacing
                                }}
                            >
                                {stat.label}
                            </Typography>
                        </div>
                    </Grid>
                ))}
            </Grid>


        </div>
    );
};

export default StatsCounter;
