import React from 'react';
import { Box } from '@mui/material';
import b1 from '../../assets/images/brands/1.svg';
import b2 from '../../assets/images/brands/2.png';
import b3 from '../../assets/images/brands/3.png';
import b4 from '../../assets/images/brands/4.png';
import b5 from '../../assets/images/brands/5.png';
import b6 from '../../assets/images/brands/6.png';
import b7 from '../../assets/images/brands/7.png';
import b8 from '../../assets/images/brands/8.png';
import b9 from '../../assets/images/brands/9.png';

const BrandSlider = () => {
    const brands = [b1, b2, b3, b4, b5, b6, b7, b8, b9];

    return (
        <section className='bg-gradient-to-r from-gray-100  to-gray-300  '>
            <Box

                sx={{
                    overflow: 'hidden',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    // backgroundColor: '#f5f5f5',
                    paddingY: 'px',
                    position: 'relative',
                    padding: 4,
                }}
            >
                <Box

                    sx={{
                        display: 'inline-block',
                        animation: 'marquee 20s linear infinite',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {brands.concat(brands).map((brand, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: 80,
                                height: 80,
                                marginX: 2,
                                display: 'inline-block',
                                backgroundColor: '#ffffff',
                                borderRadius: '10px',
                                padding: 2,
                                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.1)',

                                },
                            }}
                        >
                            <img
                                src={brand}
                                alt={`brand-${index}`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                }}
                            />
                        </Box>
                    ))}
                </Box>

                <style>
                    {`
                    @keyframes marquee {
                        0% { transform: translateX(0); }
                        100% { transform: translateX(-50%); }
                    }
                `}
                </style>
            </Box>
        </section>
    );
};

export default BrandSlider;
